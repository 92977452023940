import React from 'react'
// import Img from 'gatsby-image'
import { GatsbyImage } from "gatsby-plugin-image"
import Image from '../images/Glyph/Vector1.png'
import {Link} from 'gatsby'

const Project = ({post}) => (
        
<div className='dp__projects' >
        <div >
        {/* <Link to={post.frontmatter.slug} target='_blank'> */}
            <GatsbyImage  image= {post.frontmatter.featuredImage.childImageSharp.gatsbyImageData} style={{maxHeight:'calc(35vh - 4rem)'}}/>
            {/* </Link> */}
        </div>
    <div style={{backgroundColor:'#292929', padding: '1rem'}}>
        <div className='box'>  
            <p  style={{color:'#fff'}} className=''>{post.frontmatter.date} </p>
            <p className='ptags'> {post.frontmatter.tags}</p>
        </div>

        <div>
            <h6 style={{color:'#fff'}}>{post.frontmatter.title}</h6>
            <h5>{post.frontmatter.description}</h5>
            
            <Link to={post.frontmatter.link} target='_blank'> <p style={{color:'white', fontWeight:'400'}} >Link to Github / Website <img src={Image} alt="pertinent to project "/></p></Link>
        </div>
    </div>
</div> 
    
)

export default Project





      


