import React from "react"
import { graphql } from "gatsby"
import Project from "../components/project"
import Layout from "../components/layout"

const Projects = ({data: {allMarkdownRemark:{ edges },},}) => {
    const Posts = edges
            .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
            .map(edge => <Project key={edge.node.id} post={edge.node} />)
    
            return (
          <Layout>
          {/* <SEO title="Data Projects"  /> */}
          <div className='container__projects' id='sectiontitle'>
                <h2 style={{textAlign:'center', padding:'3rem 1.5rem'}} >  Data > Code > Decision making </h2>
                <div className='container__maintext'>
                <h6  > Data and decisions seem to be such a straightforward connection. Today, we have more data than we will ever require. Many paths enable the creation of things we like to see in the digital world. </h6>
                
                <h6 style={{paddingTop:'1.5rem'}}>However, we abound with areas that have no easy answer. What kinds of data to collect? What sort of end-consumer experience matters? Where are the control gaps at each stage of data generation to data story storytelling? These are some of the themes that keep coming back to my head. Hopefully, I will find my way to showcase projects et al in this evolving space. </h6>
                </div>
          </div>
  
            <div className='container__projects'>
                <div className='projectsgrid'>
                {Posts}  
                </div>
            </div>
          
        </Layout>
    )
  }
  
  export default Projects



export const query = graphql`
{
  allMarkdownRemark(
    filter: {frontmatter: {category: {eq: "projects"}}}
    sort: {order: DESC, fields: frontmatter___date}
  ) {
    edges {
      node {
        id
        frontmatter {
          category
          date(formatString: "MMMM DD YYYY")
          description
          link
          tags
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                transformOptions: {grayscale: false}
              )
            }
          }
        }
      }
    }
  }
}
`